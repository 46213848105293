$('#gallery img[data-enlargeable]').addClass('img-enlargeable').click(function() {
    var src = $(this).attr('src');
    var modal;

    function removeModal() {
        modal.remove();
        $('body').off('keyup.modal-close');
    }
    modal = $('<div>').css({
        background: 'RGBA(0,0,0,.7)',
        // background: 'RGBA(0,0,0,.7) url(' + src + ') no-repeat center',
        // backgroundSize: '80% auto',
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: '10000',
        top: '0',
        left: '0',
        padding: '5%',
        cursor: 'zoom-out',
        display: 'flex'
    }).click(function() {
        removeModal();
    }).appendTo('body');
    //handling ESC

    let img = '<img src="' + src + '" class="img-full-size">';
    modal.append(img);
    $('body').on('keyup.modal-close', function(e) {
        if (e.key === 'Escape') {
            removeModal();
        }
    });
});



setDeleteListener();

function setDeleteListener(){
    $('.delete-image').on('click', function (){
        $.ajax({
            url: '/api/admin/delete-image',
            data: {
                name: $(this).attr('data-name'),
                model_type: $('#modelType').val(),
                model_id: $('#modelId').val()
            },
            method: 'POST',
            success: function (response){
                let newGallery = '';
                for (let i = 0; i < response.data.length; i++){
                    newGallery += '<div class="position-relative">'
                        + '<div class="delete-image" data-name="' + response.data[i]['file_name'] + '"> Usuń </div>'
                        + '<img src="' + response.data[i]['url'] + '" width="100%" height="auto" style="cursor: zoom-in" data-enlargeable>'
                        + '</div>';
                }

                $('#gallery').html(newGallery);
                setDeleteListener();
            }
        })
    });
}
